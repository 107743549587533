import { Component, Directive, Input, OnChanges, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';

import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { DunsPipe } from '../../pipes/duns/duns.pipe';
import { GroupUiModule } from '@group-ui/group-ui-angular';
import { TranslocoRootModule } from '@core/i18n/transloco-root.module';
import { TranslocoTestingModule } from '@ngneat/transloco';
import { environment } from '@environments/environment';

@Component({
	template: `
		<ng-template #defaultRef>{{ value }}</ng-template>
		<ng-template #elRef>
			<groupui-tooltip position="top" variant="secondary">
				<groupui-text
					data-test="display-value"
					class="cursor-pointer tooltip-indicator word-break-break-word"
					[class.copied]="showCopied"
					[ngClass]="cssClasses"
					[cdkCopyToClipboard]="value"
					(click)="onSetCopied()">
					<ng-container [ngTemplateOutlet]="templateRef || defaultRef"></ng-container>
				</groupui-text>
				<groupui-text slot="tooltip">
					<strong class="display-flex align-items-center gap-groupui-spacing-3" *ngIf="showCopied" data-test="copied">
						{{ 'SHARED.TOOLTIP#COPIED' | transloco }}!
					</strong>
					<div *ngIf="!showCopied" class="max-width-300px">
						<strong class="display-flex align-items-center gap-groupui-spacing-3">{{ 'SHARED.TOOLTIP#CLICK_TO_COPY' | transloco }}:</strong>
						<div class="word-break-break-word">{{ value }}</div>
					</div>
				</groupui-text>
			</groupui-tooltip>
		</ng-template>
	`,
	standalone: true,
	imports: [CommonModule, GroupUiModule, DunsPipe, ClipboardModule, TranslocoRootModule, TranslocoTestingModule],
	styles: [
		`
			.copied {
				text-decoration-color: var(--groupui-vwag-color-green-800-primary);
				text-decoration-style: solid;
			}
		`,
	],
})
export class CopyValueComponent {
	showCopied = false;
	onSetCopied() {
		this.showCopied = true;
		setTimeout(() => {
			this.showCopied = false;
		}, environment.config.animation.changeIconDurationMs);
	}
	@Input() value!: string;
	@Input() templateRef!: TemplateRef<unknown>;
	@Input() cssClasses!: string;
	@ViewChild('elRef', { static: true }) public elRef!: TemplateRef<unknown>;
}

@Directive({
	selector: '[btrCopyValue]',
	standalone: true,
})
export class CopyValueDirective implements OnChanges {
	@Input() btrCopyValueCssClasses: string | null | undefined;
	@Input() btrCopyValue: string | null | undefined;
	private componentRef = this.viewContainer.createComponent(CopyValueComponent);
	constructor(private templateRef: TemplateRef<unknown>, private viewContainer: ViewContainerRef) {}
	ngOnChanges(): void {
		this.render();
	}
	render(val = this.btrCopyValue, cssClass = this.btrCopyValueCssClasses) {
		this.viewContainer.clear();
		this.componentRef.setInput('value', val);
		this.componentRef.setInput('templateRef', this.templateRef);
		this.componentRef.setInput('cssClasses', cssClass);
		this.viewContainer.createEmbeddedView(this.componentRef.instance.elRef);
	}
}
