import { Component, OnInit } from '@angular/core';
import { NEVER, Observable, catchError, delay, merge, tap } from 'rxjs';

import { AuthService } from '@core/services';
import { CommonModule } from '@angular/common';
import { ComplianceContent } from '@core/graphql/generated-types';
import { FetchPolicy } from '@apollo/client';
import { GroupUiModule } from '@group-ui/group-ui-angular';
import { HomeFooterComponent } from './components/home-footer/home-footer.component';
import { HomeHeaderComponent } from './components/home-header/home-header.component';
import { MasterDataService } from '@core/services/master-data/master-data.service';
import { RouterModule } from '@angular/router';
import { TranslocoRootModule } from '@core/i18n/transloco-root.module';
import { TranslocoTestingModule } from '@ngneat/transloco';
import { environment } from '@environments/environment';
import pkg from '../../../package.json';

/**
 * @description Provides general responsive layout for all pages, including static sections such as the footer.
 */
@Component({
	selector: 'btr-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss'],
	standalone: true,
	imports: [CommonModule, RouterModule, GroupUiModule, HomeHeaderComponent, HomeFooterComponent, TranslocoRootModule, TranslocoTestingModule],
})
export class HomeComponent implements OnInit {
	supportMail = environment.config.contact.email;
	version = pkg.version;
	complianceContent!: ComplianceContent | null;
	/**
	 * @description Timer showing remaining duration when logout is triggered due to inactivity
	 */
	showInactivityLogoutWarning: string | null = null;
	showInactivityLogoutProgressBar = false;
	inactivityLogoutProgressAnimationDuration = `${Math.floor(environment.config.security.durationBeforeInactivityWarningMs / 1000)}s`;
	/**
	 * @description Rxjs stream handled by an async pipe.
	 */
	componentObservable$!: Observable<unknown>;
	constructor(private masterDataService: MasterDataService, private auth: AuthService) {}

	ngOnInit(): void {
		this.initialize('cache-first');
	}
	private initialize(fetchPolicy: FetchPolicy): void {
		this.componentObservable$ = merge(
			this.masterDataService.getComplianceContent$(fetchPolicy).pipe(
				tap((content: ComplianceContent) => {
					this.complianceContent = content;
				}),
				catchError(() => {
					setTimeout(() => this.initialize(fetchPolicy), environment.config.error.retryDelayMS);
					return NEVER;
				}),
			),
			this.auth.showInactivityLogoutWarning$().pipe(
				tap((res) => (this.showInactivityLogoutWarning = res)),
				delay(environment.config.loading.waitForUIDefaultTimeoutMs),
				tap((res) => (this.showInactivityLogoutProgressBar = !!res)),
			),
		);
	}
}
