import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
	selector: 'groupui-popover[btrClickOutside]',
	standalone: true,
})
export class GroupuiEmitClickOutsidePopoverDirective {
	@Output() clickOutside = new EventEmitter<void>();

	constructor(private elementRef: ElementRef) {}

	@HostListener('document:click', ['$event.target'])
	public onClick(target: unknown) {
		const clickedInside = this.elementRef.nativeElement.contains(target);
		if (!clickedInside) {
			this.clickOutside.emit();
		}
	}
}
