/* eslint-disable sort-imports */
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import pkg from '../../../../package.json';
import { globalConfig } from '@environments/configs/globalConfig';

@Injectable()
export class ClientVersionInterceptor implements HttpInterceptor {
	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		const headerName = globalConfig.interceptor.xHeader.btrClientVersion;
		const clonedRequest = request.clone({
			setHeaders: {
				[headerName]: pkg.version,
			},
		});

		return next.handle(clonedRequest);
	}
}
