import { BehaviorSubject, Observable, delay, of, pairwise, startWith, switchMap } from 'rxjs';

import { Injectable } from '@angular/core';

export class LoadingOptions {
	/**
	 * @description Duration after which the loading is automatically removed, to prevent a dead end for the user.
	 */
	// eslint-disable-next-line no-magic-numbers
	timeoutMS = 30 * 1000;
	/**
	 * @description Delay showing loading in request finished fast enough
	 * According to NNG, user required feedback after 100-300ms that something is happening
	 */
	showDelayMS = 300;
	/**
	 * @description Will display loading at least this amount of time to avoid UI flashing
	 */
	minLoadingTimeMs = 500;
	constructor(public show = false) {}
}

@Injectable({
	providedIn: 'root',
})
export class LoadingService {
	private state$ = new BehaviorSubject<LoadingOptions>(new LoadingOptions());

	public getLoadingState$(source$: BehaviorSubject<LoadingOptions> = this.state$): Observable<LoadingOptions> {
		return source$.pipe(
			// Delay start loading by a small amount of time to prevent showing loading for fast internet
			switchMap((options) => {
				if (!options.show) {
					return of(options).pipe(delay(options.showDelayMS));
				}
				return of(options);
			}),
			startWith(this.state$.value),
			// If loading started then it must be rendered a minimum amount of time to avoid flickering
			pairwise(),
			switchMap(([previous, current]: [LoadingOptions, LoadingOptions]) => {
				// Delay end if loading is active
				if (previous && !current.show) {
					return of(current).pipe(delay((previous as LoadingOptions)?.minLoadingTimeMs));
				}
				return of(current);
			}),
		);
	}

	public startLoading(options?: LoadingOptions, source$: BehaviorSubject<LoadingOptions> = this.state$): void {
		source$.next({ ...(options ?? source$.value), show: true });
	}

	public stopLoading(source$: BehaviorSubject<LoadingOptions> = this.state$): void {
		source$.next({ ...source$.value, show: false });
	}
}
