import { ContractState, ProductPassState, ProductPassTemplateState, ProductPassValueSetState } from '@core/graphql/generated-types';

/* eslint-disable no-magic-numbers */
import { AppConfig } from '../environment.interface';
import { NAVIGATION_ITEMS } from './navigationConfig';

export const globalConfig: AppConfig = {
	urlPrefix: 'componentrepository',
	security: {
		logoutAfterInactivityDurationMS: 15 * 60 * 1000, // 15min;
		durationBeforeInactivityWarningMs: 2 * 60 * 1000, // Show warning if 2min is left
	},
	spinner: {
		duration: '1.5s',
	},
	animation: {
		fadeTimeMS: 150,
		changeIconDurationMs: 1000,
	},
	loading: {
		waitForUIDefaultTimeoutMs: 300,
		contractStateRenderTimeoutMs: 500,
	},
	formatting: {
		dateTime: {
			de: 'dd.LL.yy HH:mm:ss',
			en: 'dd.LL.yy HH:mm:ss',
		},
		dateTimeLocal: "yyyy-MM-dd'T'HH:mm",
		defaultShowNItems: 5,
	},
	form: {
		debounceTimeMs: 500,
		alphaNumericRegex: '^[a-zA-Z0-9]+$',
		alphaNumericUnderScoreRegex: '^[a-zA-Z0-9_]+$',
		selectedAllSymbol: '*',
	},
	url: {
		separator: ',',
		previousURLLocalStorageKey: 'previousURLLocalStorageKey',
		previousURLLocalStorageKeyForLocalDev: 'previousURLLocalStorageKeyForLocalDev',
	},
	contract: {
		upload: {
			allowedFileTypes: '.xsd',
		},
		stateChanges: {
			canUploadXsdContent: [ContractState.Draft],
			canChangeXsdContent: [ContractState.New, ContractState.InApproval, ContractState.Rejected],
			canCreatorDelete: [ContractState.New, ContractState.Draft, ContractState.Rejected],
			canCustomerDelete: [ContractState.Archived],
			canActivate: [ContractState.Approved, ContractState.Deactivated],
			canApprove: [ContractState.InApproval],
			canReject: [ContractState.InApproval],
			canDeactivate: [ContractState.Activated],
			canRequestApproval: [ContractState.New],
			canArchive: [ContractState.Deactivated, ContractState.Approved],
		},
	},
	productPassTemplate: {
		stateChanges: {
			canDelete: [ProductPassTemplateState.Draft],
			canActivate: [ProductPassTemplateState.Draft, ProductPassTemplateState.Inactive],
			canDeactivate: [ProductPassTemplateState.Active],
			canArchive: [ProductPassTemplateState.Inactive],
		},
	},
	productPass: {
		// Specific actions depending on state of sub resource
		draftValueSetStateChanges: {
			// Product Pass actions allowed, if sub resource in state
			canDelete: [ProductPassValueSetState.Draft, ProductPassValueSetState.Rejected],
		},
		currentValueSetStateChanges: {
			canDelete: [ProductPassValueSetState.Approved, ProductPassValueSetState.Outdated],
			canActivate: [ProductPassValueSetState.Approved, ProductPassValueSetState.Outdated],
		},
		productPassStateChanges: {
			canDeactivate: [ProductPassState.Active],
			canActivate: [ProductPassState.Inactive],
		}
	},
	component: {
		upload: {
			allowedFileTypes: '.xml',
		},
	},
	navigationConfig: NAVIGATION_ITEMS,
	error: {
		separator: '||',
		ignoreErrorMessageForComponentAdditionalData: 'not allowed to access',
		ignoreErrorMessageForComponentDetails: 'not allowed to access',
		retryDelayMS: 15000,
		disableErrorModalForGraphqlOperation: [
			'getCompanyData',
			'getComponentById',
			'getComponentDetails',
			'getComponentAdditionalData',
			'componentHistoryById',
			'getComponentSubcomponents',
			'componentReadAccessById',
			'getComplianceContent',
		],
	},
	serviceWorker: {
		enabled: undefined,
		registrationStrategy: 'registerWhenStable:30000',
		refreshTimerMS: 0,
	},
	interceptor: {
		xHeader: {
			btrClientVersion: 'BTR-CLIENT-VERSION',
			btrHealthCheck: 'BTR-HEALTH-CHECK',
		},
	},
	home: {
		complianceLinkPollingMS: 15 * 60 * 1000,
	},
	contact: {
		email: 'component-repository.vwag.r.wob@volkswagen.de',
	},
};
