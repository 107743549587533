import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, from, switchMap } from 'rxjs';

import { AuthService } from '@core/services';
import { globalConfig } from '@environments/configs/globalConfig';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	
	constructor() {}

	authService = inject(AuthService);

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		const headerName = globalConfig.interceptor.xHeader.btrHealthCheck;
		return from(this.authService.getCurrentAccessToken()).pipe(
			switchMap((token) => {
				if (request.url.includes('/assets/i18n')) {
					// When loading the assests e.g. i18n there should not be an token attached
					return next.handle(request);
				} else if (request.headers.has(headerName)) {
					return next.handle(request);
				} else {
					const modifiedRequest = request.clone({
						headers: request.headers.set('authorization', `Bearer ${token}`),
					});
					return next.handle(modifiedRequest);
				}
			}),
		);
	}
}
