<ng-container *transloco="let t">
		<div class="main">
			<div class="contentframe">
				<groupui-grid marginType="flexible" gutter="30px">
					<groupui-grid-row>
						<groupui-grid-col>
							<div class="page-not-found">
								<h1>: (</h1>
								<br />{{ t('SHARED.ERROR.PAGE_NOT_FOUND') }}
							</div>
						</groupui-grid-col>
					</groupui-grid-row>
				</groupui-grid>
			</div>
		</div>
</ng-container>
