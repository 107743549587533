export enum CognitoAuthEvent {
	SIGNIN_FAILURE = 'signIn_failure',
	SIGNIN = 'signIn',
	SIGNOUT = 'signOut',
}

export enum CognitoTokenFieldName {
	PARTNER_ID = 'partnerId',
	EMAIL = 'email',
	COGNITO_GROUPS = 'cognito:groups',
	PREFFERED_USERNAME = 'preferred_username',
	PREFIX_ROLE = 'role:',
}

export enum CognitoHubChannel {
	AUTH = 'auth',
}
