import { DiscardChanges, DiscardChangesModal } from './discard-changes.model';

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * @description
 *
 * DiscardChangesGuard checks the leaving component for `hasChanges:boolean = true`
 * If true, it will will open an clarity modal asking the user to either confirm or cancel the navigation action.
 *
 */
@Injectable({
	providedIn: 'root',
})
export class DiscardChangesGuard {
	/**
	 * @description Broadcasting if discard changes modal show be opened or closed
	 */
	private showDiscardChangeModal$ = new Subject<DiscardChangesModal | null>();
	/**
	 * @description Public source to subscribe to modal
	 */
	showDiscardChanges$ = this.showDiscardChangeModal$.asObservable();
	/**
	 * @description Emits event to show discard changes alert
	 */
	canDeactivate(component: DiscardChanges): Promise<boolean> {
		const { hasChanges } = component;
		if (hasChanges === true) {
			return this.showDiscardChanges(component);
		}
		return Promise.resolve(true);
	}
	/**
	 * @description Triggers the discard changes modal to show and wait for it's response
	 */
	public showDiscardChanges(component: DiscardChanges): Promise<boolean> {
		return new Promise((res) => {
			this.showDiscardChangeModal$.next({
				hasChanges: component.hasChanges,
				discardChangesHeader: component.discardChangesHeader || 'SHARED.MODAL.TITLE#DISCARD_CHANGES',
				discardChangesMessage: component.discardChangesMessage || 'SHARED.MODAL.MESSAGE#DISCARD_CHANGES',
				discardChangesDiscardButtonText: component.discardChangesDiscardButtonText || 'FORMS.ACTIONS.DISCARD',
				onConfirm: () => {
					component.hasChanges = false;
					this.showDiscardChangeModal$.next(null);
					res(true);
				},
				onCancel: () => {
					this.showDiscardChangeModal$.next(null);
					res(false);
				},
				onModalOpenChange: (isOpen: boolean) => {
					if (!isOpen) {
						this.showDiscardChangeModal$.next(null);
						res(false);
					}
				},
			});
		});
	}
}
