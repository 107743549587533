import { randNumber } from '@ngneat/falso';

export const CONSTANTS = {
	MOCK_SERVER_PORT: 4001,
};

export const DEFAULT_PREFERRED_USERNAME = 'Local Test User';
export const DEFAULT_FIRST_VALUE_ID = '1';
export const DEFAULT_GBT_VALUE_ID = 'YA010C3PETFT9J1100D2E0000032';
export const DEFAULT_SERIAL_NUMBER_VALUE_ID = '288 61101459966';
export const DEFAULT_CUSTOMER_PARTNER_ID = '000000001';
export const DEFAULT_SUPPLIER_PARTNER_ID = '000000002';
export const DEFAULT_APPROVER_PARTNER_ID = '000000003';
export const DEFAULT_CREATOR_PARTNER_ID = '000000004';
// This is a real example of an internal partner
export const DEFAULT_INTERNAL_PARTNER_ID = '0200-00210Module1';

export const DEFAULT_VALUE_ID_WITH_ERROR = 'error';

export const DEFAULT_EMPTY_LIST_FILTER_VALUE = 'empty';

export const COMPONENT_LIST_ITEM_LIMIT = 200;

export const KSU_CLASS = '7.2';

export const DEFAULT_TIME_TO_LIVE = '180';

export const wait = (time: number) => new Promise((resolve) => setTimeout(resolve, time));

export const randDefaultWait = () => wait(randNumber({ min: 500, max: 1000 }));
