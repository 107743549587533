import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { DateTimeService, TIMEZONE_UTC, Timezone, TimezoneByContinent } from '@core/services/date-time/date-time.service';

import { CommonModule } from '@angular/common';
import { FocusOnArrowKeyDirective } from '@core/directives/focus-on-arrow-key.directive';
import { GroupUiEmitChangeOnInputDirective } from '@core/directives/group-ui/group-ui-emit-change-on-input.directive';
import { GroupUiModule } from '@group-ui/group-ui-angular';
import { HttpClientModule } from '@angular/common/http';
import { TranslocoRootModule } from '@core/i18n/transloco-root.module';
import { TranslocoTestingModule } from '@ngneat/transloco';

@Component({
	selector: 'btr-timezone-select',
	standalone: true,
	imports: [
		CommonModule,
		GroupUiModule,
		GroupUiEmitChangeOnInputDirective,
		FocusOnArrowKeyDirective,
		TranslocoRootModule,
		TranslocoTestingModule,
		HttpClientModule,
	],
	templateUrl: './timezone-select.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styles: [
		`
			.min-width-80px {
				min-width: 80px;
			}
		`,
	],
})
export class TimezoneSelectComponent {
	private dateTimeService = inject(DateTimeService);

	searchValue = '';
	timezonesByContinent = this.dateTimeService.getTimezonesByContinent();
	timezoneUTC = TIMEZONE_UTC;
	timezoneBrowser = this.dateTimeService.getBrowserTimezone();
	timezoneSelected$ = this.dateTimeService.getSelectedTimezone$();
	onSetTimezone(timezone: Timezone) {
		this.dateTimeService.setTimezone(timezone);
		this.reset();
		// Used to close popover
		const event = new KeyboardEvent('keydown', { code: 'Escape' });
		document.dispatchEvent(event);
	}
	onSearchTimezone(evt: Event) {
		const searchValue = (evt.target as HTMLInputElement).value;
		this.searchValue = searchValue;
		this.timezonesByContinent = this.dateTimeService.getTimezonesByContinent(this.dateTimeService.filterTimezone(searchValue));
	}

	reset() {
		this.searchValue = '';
		this.timezonesByContinent = this.dateTimeService.getTimezonesByContinent();
	}
	trackByName(idx: number, timezone: Timezone | TimezoneByContinent): string {
		return timezone.name;
	}
}
