import { Timezone } from '@core/services/date-time/date-time.service';

/* eslint-disable max-lines */
export const TIMEZONES: Timezone[] = [
	{
		name: 'Africa/Algiers',
		continentName: 'Africa',
		countryName: 'Algeria',
		abbreviation: 'CET',
		utcOffset: 'UTC+01:00',
	},
	{
		name: 'Africa/Luanda',
		continentName: 'Africa',
		countryName: 'Angola',
		abbreviation: 'WAT',
		utcOffset: 'UTC+01:00',
	},
	{
		name: 'Africa/Porto-Novo',
		continentName: 'Africa',
		countryName: 'Benin',
		abbreviation: 'WAT',
		utcOffset: 'UTC+01:00',
	},
	{
		name: 'Africa/Gaborone',
		continentName: 'Africa',
		countryName: 'Botswana',
		abbreviation: 'CAT',
		utcOffset: 'UTC+02:00',
	},
	{
		name: 'Africa/Ouagadougou',
		continentName: 'Africa',
		countryName: 'Burkina Faso',
		abbreviation: 'GMT',
		utcOffset: 'UTC+00:00',
	},
	{
		name: 'Africa/Bujumbura',
		continentName: 'Africa',
		countryName: 'Burundi',
		abbreviation: 'CAT',
		utcOffset: 'UTC+02:00',
	},
	{
		name: 'Atlantic/Cape_Verde',
		continentName: 'Africa',
		countryName: 'Cabo Verde',
		abbreviation: 'CVT',
		utcOffset: 'UTC-01:00',
	},
	{
		name: 'Africa/Douala',
		continentName: 'Africa',
		countryName: 'Cameroon',
		abbreviation: 'WAT',
		utcOffset: 'UTC+01:00',
	},
	{
		name: 'Africa/Bangui',
		continentName: 'Africa',
		countryName: 'Central African Republic',
		abbreviation: 'WAT',
		utcOffset: 'UTC+01:00',
	},
	{
		name: 'Africa/Ndjamena',
		continentName: 'Africa',
		countryName: 'Chad',
		abbreviation: 'WAT',
		utcOffset: 'UTC+01:00',
	},
	{
		name: 'Indian/Comoro',
		continentName: 'Africa',
		countryName: 'Comoros',
		abbreviation: 'EAT',
		utcOffset: 'UTC+03:00',
	},
	{
		name: 'Africa/Kinshasa',
		continentName: 'Africa',
		countryName: 'Democratic Republic of the Congo',
		abbreviation: 'WAT',
		utcOffset: 'UTC+01:00',
	},
	{
		name: 'Africa/Lubumbashi',
		continentName: 'Africa',
		countryName: 'Democratic Republic of the Congo',
		abbreviation: 'CAT',
		utcOffset: 'UTC+02:00',
	},
	{
		name: 'Africa/Djibouti',
		continentName: 'Africa',
		countryName: 'Djibouti',
		abbreviation: 'EAT',
		utcOffset: 'UTC+03:00',
	},
	{
		name: 'Africa/Cairo',
		continentName: 'Africa',
		countryName: 'Egypt',
		abbreviation: 'EET',
		utcOffset: 'UTC+02:00',
	},
	{
		name: 'Africa/Malabo',
		continentName: 'Africa',
		countryName: 'Equatorial Guinea',
		abbreviation: 'WAT',
		utcOffset: 'UTC+01:00',
	},
	{
		name: 'Africa/Asmara',
		continentName: 'Africa',
		countryName: 'Eritrea',
		abbreviation: 'EAT',
		utcOffset: 'UTC+03:00',
	},
	{
		name: 'Africa/Mbabane',
		continentName: 'Africa',
		countryName: 'Eswatini',
		abbreviation: 'SAST',
		utcOffset: 'UTC+02:00',
	},
	{
		name: 'Africa/Addis_Ababa',
		continentName: 'Africa',
		countryName: 'Ethiopia',
		abbreviation: 'EAT',
		utcOffset: 'UTC+03:00',
	},
	{
		name: 'Africa/Libreville',
		continentName: 'Africa',
		countryName: 'Gabon',
		abbreviation: 'WAT',
		utcOffset: 'UTC+01:00',
	},
	{
		name: 'Africa/Banjul',
		continentName: 'Africa',
		countryName: 'Gambia',
		abbreviation: 'GMT',
		utcOffset: 'UTC+00:00',
	},
	{
		name: 'Africa/Accra',
		continentName: 'Africa',
		countryName: 'Ghana',
		abbreviation: 'GMT',
		utcOffset: 'UTC+00:00',
	},
	{
		name: 'Africa/Conakry',
		continentName: 'Africa',
		countryName: 'Guinea',
		abbreviation: 'GMT',
		utcOffset: 'UTC+00:00',
	},
	{
		name: 'Africa/Bissau',
		continentName: 'Africa',
		countryName: 'Guinea-Bissau',
		abbreviation: 'GMT',
		utcOffset: 'UTC+00:00',
	},
	{
		name: 'Africa/Abidjan',
		continentName: 'Africa',
		countryName: 'Ivory Coast',
		abbreviation: 'GMT',
		utcOffset: 'UTC+00:00',
	},
	{
		name: 'Africa/Nairobi',
		continentName: 'Africa',
		countryName: 'Kenya',
		abbreviation: 'EAT',
		utcOffset: 'UTC+03:00',
	},
	{
		name: 'Africa/Maseru',
		continentName: 'Africa',
		countryName: 'Lesotho',
		abbreviation: 'SAST',
		utcOffset: 'UTC+02:00',
	},
	{
		name: 'Africa/Monrovia',
		continentName: 'Africa',
		countryName: 'Liberia',
		abbreviation: 'GMT',
		utcOffset: 'UTC+00:00',
	},
	{
		name: 'Africa/Tripoli',
		continentName: 'Africa',
		countryName: 'Libya',
		abbreviation: 'EET',
		utcOffset: 'UTC+02:00',
	},
	{
		name: 'Indian/Antananarivo',
		continentName: 'Africa',
		countryName: 'Madagascar',
		abbreviation: 'EAT',
		utcOffset: 'UTC+03:00',
	},
	{
		name: 'Africa/Blantyre',
		continentName: 'Africa',
		countryName: 'Malawi',
		abbreviation: 'CAT',
		utcOffset: 'UTC+02:00',
	},
	{
		name: 'Africa/Bamako',
		continentName: 'Africa',
		countryName: 'Mali',
		abbreviation: 'GMT',
		utcOffset: 'UTC+00:00',
	},
	{
		name: 'Africa/Nouakchott',
		continentName: 'Africa',
		countryName: 'Mauritania',
		abbreviation: 'GMT',
		utcOffset: 'UTC+00:00',
	},
	{
		name: 'Indian/Mauritius',
		continentName: 'Africa',
		countryName: 'Mauritius',
		abbreviation: 'MUT',
		utcOffset: 'UTC+04:00',
	},
	{
		name: 'Indian/Mayotte',
		continentName: 'Africa',
		countryName: 'Mayotte',
		abbreviation: 'EAT',
		utcOffset: 'UTC+03:00',
	},
	{
		name: 'Africa/Casablanca',
		continentName: 'Africa',
		countryName: 'Morocco',
		abbreviation: 'WET',
		utcOffset: 'UTC+00:00',
	},
	{
		name: 'Africa/Maputo',
		continentName: 'Africa',
		countryName: 'Mozambique',
		abbreviation: 'CAT',
		utcOffset: 'UTC+02:00',
	},
	{
		name: 'Africa/Windhoek',
		continentName: 'Africa',
		countryName: 'Namibia',
		abbreviation: 'CAT',
		utcOffset: 'UTC+01:00',
	},
	{
		name: 'Africa/Niamey',
		continentName: 'Africa',
		countryName: 'Niger',
		abbreviation: 'WAT',
		utcOffset: 'UTC+01:00',
	},
	{
		name: 'Africa/Lagos',
		continentName: 'Africa',
		countryName: 'Nigeria',
		abbreviation: 'WAT',
		utcOffset: 'UTC+01:00',
	},
	{
		name: 'Africa/Brazzaville',
		continentName: 'Africa',
		countryName: 'Republic of the Congo',
		abbreviation: 'WAT',
		utcOffset: 'UTC+01:00',
	},
	{
		name: 'Indian/Reunion',
		continentName: 'Africa',
		countryName: 'Reunion',
		abbreviation: 'RET',
		utcOffset: 'UTC+04:00',
	},
	{
		name: 'Africa/Kigali',
		continentName: 'Africa',
		countryName: 'Rwanda',
		abbreviation: 'CAT',
		utcOffset: 'UTC+02:00',
	},
	{
		name: 'Atlantic/St_Helena',
		continentName: 'Africa',
		countryName: 'Saint Helena',
		abbreviation: 'GMT',
		utcOffset: 'UTC+00:00',
	},
	{
		name: 'Africa/Sao_Tome',
		continentName: 'Africa',
		countryName: 'Sao Tome and Principe',
		abbreviation: 'GMT',
		utcOffset: 'UTC+00:00',
	},
	{
		name: 'Africa/Dakar',
		continentName: 'Africa',
		countryName: 'Senegal',
		abbreviation: 'GMT',
		utcOffset: 'UTC+00:00',
	},
	{
		name: 'Indian/Mahe',
		continentName: 'Africa',
		countryName: 'Seychelles',
		abbreviation: 'SCT',
		utcOffset: 'UTC+04:00',
	},
	{
		name: 'Africa/Freetown',
		continentName: 'Africa',
		countryName: 'Sierra Leone',
		abbreviation: 'GMT',
		utcOffset: 'UTC+00:00',
	},
	{
		name: 'Africa/Mogadishu',
		continentName: 'Africa',
		countryName: 'Somalia',
		abbreviation: 'EAT',
		utcOffset: 'UTC+03:00',
	},
	{
		name: 'Africa/Johannesburg',
		continentName: 'Africa',
		countryName: 'South Africa',
		abbreviation: 'SAST',
		utcOffset: 'UTC+02:00',
	},
	{
		name: 'Africa/Juba',
		continentName: 'Africa',
		countryName: 'South Sudan',
		abbreviation: 'CAT',
		utcOffset: 'UTC+02:00',
	},
	{
		name: 'Africa/Khartoum',
		continentName: 'Africa',
		countryName: 'Sudan',
		abbreviation: 'CAT',
		utcOffset: 'UTC+02:00',
	},
	{
		name: 'Africa/Dar_es_Salaam',
		continentName: 'Africa',
		countryName: 'Tanzania',
		abbreviation: 'EAT',
		utcOffset: 'UTC+03:00',
	},
	{
		name: 'Africa/Lome',
		continentName: 'Africa',
		countryName: 'Togo',
		abbreviation: 'GMT',
		utcOffset: 'UTC+00:00',
	},
	{
		name: 'Africa/Tunis',
		continentName: 'Africa',
		countryName: 'Tunisia',
		abbreviation: 'CET',
		utcOffset: 'UTC+01:00',
	},
	{
		name: 'Africa/Kampala',
		continentName: 'Africa',
		countryName: 'Uganda',
		abbreviation: 'EAT',
		utcOffset: 'UTC+03:00',
	},
	{
		name: 'Africa/El_Aaiun',
		continentName: 'Africa',
		countryName: 'Western Sahara',
		abbreviation: 'WET',
		utcOffset: 'UTC+00:00',
	},
	{
		name: 'Africa/Lusaka',
		continentName: 'Africa',
		countryName: 'Zambia',
		abbreviation: 'CAT',
		utcOffset: 'UTC+02:00',
	},
	{
		name: 'Africa/Harare',
		continentName: 'Africa',
		countryName: 'Zimbabwe',
		abbreviation: 'CAT',
		utcOffset: 'UTC+02:00',
	},
	{
		name: 'Antarctica/Palmer',
		continentName: 'Antarctica',
		countryName: 'Antarctica',
		abbreviation: 'CLT',
		utcOffset: 'UTC-03:00',
	},
	{
		name: 'Antarctica/Troll',
		continentName: 'Antarctica',
		countryName: 'Antarctica',
		abbreviation: 'GMT',
		utcOffset: 'UTC+00:00',
	},
	{
		name: 'Antarctica/Syowa',
		continentName: 'Antarctica',
		countryName: 'Antarctica',
		abbreviation: 'SYOT',
		utcOffset: 'UTC+03:00',
	},
	{
		name: 'Antarctica/Mawson',
		continentName: 'Antarctica',
		countryName: 'Antarctica',
		abbreviation: 'MAWT',
		utcOffset: 'UTC+05:00',
	},
	{
		name: 'Antarctica/Vostok',
		continentName: 'Antarctica',
		countryName: 'Antarctica',
		abbreviation: 'VOST',
		utcOffset: 'UTC+06:00',
	},
	{
		name: 'Antarctica/Davis',
		continentName: 'Antarctica',
		countryName: 'Antarctica',
		abbreviation: 'DAVT',
		utcOffset: 'UTC+07:00',
	},
	{
		name: 'Antarctica/DumontDUrville',
		continentName: 'Antarctica',
		countryName: 'Antarctica',
		abbreviation: 'DDUT',
		utcOffset: 'UTC+10:00',
	},
	{
		name: 'Antarctica/Casey',
		continentName: 'Antarctica',
		countryName: 'Antarctica',
		abbreviation: 'CAST',
		utcOffset: 'UTC+11:00',
	},
	{
		name: 'Antarctica/McMurdo',
		continentName: 'Antarctica',
		countryName: 'Antarctica',
		abbreviation: 'NZST',
		utcOffset: 'UTC+12:00',
	},
	{
		name: 'Indian/Kerguelen',
		continentName: 'Antarctica',
		countryName: 'French Southern Territories',
		abbreviation: 'FSAT',
		utcOffset: 'UTC+05:00',
	},
	{
		name: 'Atlantic/South_Georgia',
		continentName: 'Antarctica',
		countryName: 'South Georgia and the South Sandwich Islands',
		abbreviation: 'GST',
		utcOffset: 'UTC-02:00',
	},
	{
		name: 'Asia/Kabul',
		continentName: 'Asia',
		countryName: 'Afghanistan',
		abbreviation: 'AFT',
		utcOffset: 'UTC+04:30',
	},
	{
		name: 'Asia/Yerevan',
		continentName: 'Asia',
		countryName: 'Armenia',
		abbreviation: 'AMT',
		utcOffset: 'UTC+04:00',
	},
	{
		name: 'Asia/Baku',
		continentName: 'Asia',
		countryName: 'Azerbaijan',
		abbreviation: 'AZT',
		utcOffset: 'UTC+04:00',
	},
	{
		name: 'Asia/Bahrain',
		continentName: 'Asia',
		countryName: 'Bahrain',
		abbreviation: 'AST',
		utcOffset: 'UTC+03:00',
	},
	{
		name: 'Asia/Dhaka',
		continentName: 'Asia',
		countryName: 'Bangladesh',
		abbreviation: 'BST',
		utcOffset: 'UTC+06:00',
	},
	{
		name: 'Asia/Thimphu',
		continentName: 'Asia',
		countryName: 'Bhutan',
		abbreviation: 'BTT',
		utcOffset: 'UTC+06:00',
	},
	{
		name: 'Indian/Chagos',
		continentName: 'Asia',
		countryName: 'British Indian Ocean Territory',
		abbreviation: 'IOT',
		utcOffset: 'UTC+06:00',
	},
	{
		name: 'Asia/Brunei',
		continentName: 'Asia',
		countryName: 'Brunei',
		abbreviation: 'BNT',
		utcOffset: 'UTC+08:00',
	},
	{
		name: 'Asia/Phnom_Penh',
		continentName: 'Asia',
		countryName: 'Cambodia',
		abbreviation: 'ICT',
		utcOffset: 'UTC+07:00',
	},
	{
		name: 'Asia/Urumqi',
		continentName: 'Asia',
		countryName: 'China',
		abbreviation: 'CST',
		utcOffset: 'UTC+06:00',
	},
	{
		name: 'Asia/Shanghai',
		continentName: 'Asia',
		countryName: 'China',
		abbreviation: 'CST',
		utcOffset: 'UTC+08:00',
	},
	{
		name: 'Indian/Cocos',
		continentName: 'Asia',
		countryName: 'Cocos Islands',
		abbreviation: 'CCT',
		utcOffset: 'UTC+06:30',
	},
	{
		name: 'Asia/Tbilisi',
		continentName: 'Asia',
		countryName: 'Georgia',
		abbreviation: 'GET',
		utcOffset: 'UTC+04:00',
	},
	{
		name: 'Asia/Hong_Kong',
		continentName: 'Asia',
		countryName: 'Hong Kong',
		abbreviation: 'HKT',
		utcOffset: 'UTC+08:00',
	},
	{
		name: 'Asia/Kolkata',
		continentName: 'Asia',
		countryName: 'India',
		abbreviation: 'IST',
		utcOffset: 'UTC+05:30',
	},
	{
		name: 'Asia/Jakarta',
		continentName: 'Asia',
		countryName: 'Indonesia',
		abbreviation: 'WIB',
		utcOffset: 'UTC+07:00',
	},
	{
		name: 'Asia/Makassar',
		continentName: 'Asia',
		countryName: 'Indonesia',
		abbreviation: 'WITA',
		utcOffset: 'UTC+08:00',
	},
	{
		name: 'Asia/Jayapura',
		continentName: 'Asia',
		countryName: 'Indonesia',
		abbreviation: 'WIT',
		utcOffset: 'UTC+09:00',
	},
	{
		name: 'Asia/Tehran',
		continentName: 'Asia',
		countryName: 'Iran',
		abbreviation: 'IRST',
		utcOffset: 'UTC+03:30',
	},
	{
		name: 'Asia/Baghdad',
		continentName: 'Asia',
		countryName: 'Iraq',
		abbreviation: 'AST',
		utcOffset: 'UTC+03:00',
	},
	{
		name: 'Asia/Jerusalem',
		continentName: 'Asia',
		countryName: 'Israel',
		abbreviation: 'IST',
		utcOffset: 'UTC+02:00',
	},
	{
		name: 'Asia/Tokyo',
		continentName: 'Asia',
		countryName: 'Japan',
		abbreviation: 'JST',
		utcOffset: 'UTC+09:00',
	},
	{
		name: 'Asia/Amman',
		continentName: 'Asia',
		countryName: 'Jordan',
		abbreviation: 'GMT+3',
		utcOffset: 'UTC+03:00',
	},
	{
		name: 'Asia/Aqtobe',
		continentName: 'Asia',
		countryName: 'Kazakhstan',
		abbreviation: 'AQTT',
		utcOffset: 'UTC+05:00',
	},
	{
		name: 'Asia/Almaty',
		continentName: 'Asia',
		countryName: 'Kazakhstan',
		abbreviation: 'ALMT',
		utcOffset: 'UTC+06:00',
	},
	{
		name: 'Asia/Kuwait',
		continentName: 'Asia',
		countryName: 'Kuwait',
		abbreviation: 'AST',
		utcOffset: 'UTC+03:00',
	},
	{
		name: 'Asia/Bishkek',
		continentName: 'Asia',
		countryName: 'Kyrgyzstan',
		abbreviation: 'KGT',
		utcOffset: 'UTC+06:00',
	},
	{
		name: 'Asia/Vientiane',
		continentName: 'Asia',
		countryName: 'Laos',
		abbreviation: 'ICT',
		utcOffset: 'UTC+07:00',
	},
	{
		name: 'Asia/Beirut',
		continentName: 'Asia',
		countryName: 'Lebanon',
		abbreviation: 'EET',
		utcOffset: 'UTC+02:00',
	},
	{
		name: 'Asia/Macau',
		continentName: 'Asia',
		countryName: 'Macao',
		abbreviation: 'CST',
		utcOffset: 'UTC+08:00',
	},
	{
		name: 'Asia/Kuala_Lumpur',
		continentName: 'Asia',
		countryName: 'Malaysia',
		abbreviation: 'MYT',
		utcOffset: 'UTC+08:00',
	},
	{
		name: 'Indian/Maldives',
		continentName: 'Asia',
		countryName: 'Maldives',
		abbreviation: 'MVT',
		utcOffset: 'UTC+05:00',
	},
	{
		name: 'Asia/Hovd',
		continentName: 'Asia',
		countryName: 'Mongolia',
		abbreviation: 'HOVT',
		utcOffset: 'UTC+07:00',
	},
	{
		name: 'Asia/Ulaanbaatar',
		continentName: 'Asia',
		countryName: 'Mongolia',
		abbreviation: 'ULAT',
		utcOffset: 'UTC+08:00',
	},
	{
		name: 'Asia/Yangon',
		continentName: 'Asia',
		countryName: 'Myanmar',
		abbreviation: 'MMT',
		utcOffset: 'UTC+06:30',
	},
	{
		name: 'Asia/Kathmandu',
		continentName: 'Asia',
		countryName: 'Nepal',
		abbreviation: 'NPT',
		utcOffset: 'UTC+05:45',
	},
	{
		name: 'Asia/Pyongyang',
		continentName: 'Asia',
		countryName: 'North Korea',
		abbreviation: 'KST',
		utcOffset: 'UTC+09:00',
	},
	{
		name: 'Asia/Muscat',
		continentName: 'Asia',
		countryName: 'Oman',
		abbreviation: 'GST',
		utcOffset: 'UTC+04:00',
	},
	{
		name: 'Asia/Karachi',
		continentName: 'Asia',
		countryName: 'Pakistan',
		abbreviation: 'PKT',
		utcOffset: 'UTC+05:00',
	},
	{
		name: 'Asia/Hebron',
		continentName: 'Asia',
		countryName: 'Palestinian Territory',
		abbreviation: 'EET',
		utcOffset: 'UTC+02:00',
	},
	{
		name: 'Asia/Manila',
		continentName: 'Asia',
		countryName: 'Philippines',
		abbreviation: 'PHT',
		utcOffset: 'UTC+08:00',
	},
	{
		name: 'Asia/Qatar',
		continentName: 'Asia',
		countryName: 'Qatar',
		abbreviation: 'AST',
		utcOffset: 'UTC+03:00',
	},
	{
		name: 'Asia/Riyadh',
		continentName: 'Asia',
		countryName: 'Saudi Arabia',
		abbreviation: 'AST',
		utcOffset: 'UTC+03:00',
	},
	{
		name: 'Asia/Singapore',
		continentName: 'Asia',
		countryName: 'Singapore',
		abbreviation: 'SGT',
		utcOffset: 'UTC+08:00',
	},
	{
		name: 'Asia/Seoul',
		continentName: 'Asia',
		countryName: 'South Korea',
		abbreviation: 'KST',
		utcOffset: 'UTC+09:00',
	},
	{
		name: 'Asia/Colombo',
		continentName: 'Asia',
		countryName: 'Sri Lanka',
		abbreviation: 'IST',
		utcOffset: 'UTC+05:30',
	},
	{
		name: 'Asia/Damascus',
		continentName: 'Asia',
		countryName: 'Syria',
		abbreviation: 'GMT+3',
		utcOffset: 'UTC+03:00',
	},
	{
		name: 'Asia/Taipei',
		continentName: 'Asia',
		countryName: 'Taiwan',
		abbreviation: 'TWT',
		utcOffset: 'UTC+08:00',
	},
	{
		name: 'Asia/Dushanbe',
		continentName: 'Asia',
		countryName: 'Tajikistan',
		abbreviation: 'TJT',
		utcOffset: 'UTC+05:00',
	},
	{
		name: 'Asia/Bangkok',
		continentName: 'Asia',
		countryName: 'Thailand',
		abbreviation: 'ICT',
		utcOffset: 'UTC+07:00',
	},
	{
		name: 'Europe/Istanbul',
		continentName: 'Asia',
		countryName: 'Turkey',
		abbreviation: 'TRT',
		utcOffset: 'UTC+03:00',
	},
	{
		name: 'Asia/Ashgabat',
		continentName: 'Asia',
		countryName: 'Turkmenistan',
		abbreviation: 'TMT',
		utcOffset: 'UTC+05:00',
	},
	{
		name: 'Asia/Dubai',
		continentName: 'Asia',
		countryName: 'United Arab Emirates',
		abbreviation: 'GST',
		utcOffset: 'UTC+04:00',
	},
	{
		name: 'Asia/Tashkent',
		continentName: 'Asia',
		countryName: 'Uzbekistan',
		abbreviation: 'UZT',
		utcOffset: 'UTC+05:00',
	},
	{
		name: 'Asia/Ho_Chi_Minh',
		continentName: 'Asia',
		countryName: 'Vietnam',
		abbreviation: 'ICT',
		utcOffset: 'UTC+07:00',
	},
	{
		name: 'Asia/Aden',
		continentName: 'Asia',
		countryName: 'Yemen',
		abbreviation: 'AST',
		utcOffset: 'UTC+03:00',
	},
	{
		name: 'Europe/Mariehamn',
		continentName: 'Europe',
		countryName: 'Aland Islands',
		abbreviation: 'EET',
		utcOffset: 'UTC+02:00',
	},
	{
		name: 'Europe/Tirane',
		continentName: 'Europe',
		countryName: 'Albania',
		abbreviation: 'CET',
		utcOffset: 'UTC+01:00',
	},
	{
		name: 'Europe/Andorra',
		continentName: 'Europe',
		countryName: 'Andorra',
		abbreviation: 'CET',
		utcOffset: 'UTC+01:00',
	},
	{
		name: 'Europe/Vienna',
		continentName: 'Europe',
		countryName: 'Austria',
		abbreviation: 'CET',
		utcOffset: 'UTC+01:00',
	},
	{
		name: 'Europe/Minsk',
		continentName: 'Europe',
		countryName: 'Belarus',
		abbreviation: 'MSK',
		utcOffset: 'UTC+03:00',
	},
	{
		name: 'Europe/Brussels',
		continentName: 'Europe',
		countryName: 'Belgium',
		abbreviation: 'CET',
		utcOffset: 'UTC+01:00',
	},
	{
		name: 'Europe/Sarajevo',
		continentName: 'Europe',
		countryName: 'Bosnia and Herzegovina',
		abbreviation: 'CET',
		utcOffset: 'UTC+01:00',
	},
	{
		name: 'Europe/Sofia',
		continentName: 'Europe',
		countryName: 'Bulgaria',
		abbreviation: 'EET',
		utcOffset: 'UTC+02:00',
	},
	{
		name: 'Europe/Zagreb',
		continentName: 'Europe',
		countryName: 'Croatia',
		abbreviation: 'CET',
		utcOffset: 'UTC+01:00',
	},
	{
		name: 'Asia/Nicosia',
		continentName: 'Europe',
		countryName: 'Cyprus',
		abbreviation: 'EET',
		utcOffset: 'UTC+02:00',
	},
	{
		name: 'Europe/Prague',
		continentName: 'Europe',
		countryName: 'Czechia',
		abbreviation: 'CET',
		utcOffset: 'UTC+01:00',
	},
	{
		name: 'Europe/Copenhagen',
		continentName: 'Europe',
		countryName: 'Denmark',
		abbreviation: 'CET',
		utcOffset: 'UTC+01:00',
	},
	{
		name: 'Europe/Tallinn',
		continentName: 'Europe',
		countryName: 'Estonia',
		abbreviation: 'EET',
		utcOffset: 'UTC+02:00',
	},
	{
		name: 'Atlantic/Faroe',
		continentName: 'Europe',
		countryName: 'Faroe Islands',
		abbreviation: 'WET',
		utcOffset: 'UTC+00:00',
	},
	{
		name: 'Europe/Helsinki',
		continentName: 'Europe',
		countryName: 'Finland',
		abbreviation: 'EET',
		utcOffset: 'UTC+02:00',
	},
	{
		name: 'Europe/Paris',
		continentName: 'Europe',
		countryName: 'France',
		abbreviation: 'CET',
		utcOffset: 'UTC+01:00',
	},
	{
		name: 'Europe/Berlin',
		continentName: 'Europe',
		countryName: 'Germany',
		abbreviation: 'CET',
		utcOffset: 'UTC+01:00',
	},
	{
		name: 'Europe/Gibraltar',
		continentName: 'Europe',
		countryName: 'Gibraltar',
		abbreviation: 'CET',
		utcOffset: 'UTC+01:00',
	},
	{
		name: 'Europe/Athens',
		continentName: 'Europe',
		countryName: 'Greece',
		abbreviation: 'EET',
		utcOffset: 'UTC+02:00',
	},
	{
		name: 'Europe/Guernsey',
		continentName: 'Europe',
		countryName: 'Guernsey',
		abbreviation: 'GMT',
		utcOffset: 'UTC+00:00',
	},
	{
		name: 'Europe/Budapest',
		continentName: 'Europe',
		countryName: 'Hungary',
		abbreviation: 'CET',
		utcOffset: 'UTC+01:00',
	},
	{
		name: 'Atlantic/Reykjavik',
		continentName: 'Europe',
		countryName: 'Iceland',
		abbreviation: 'GMT',
		utcOffset: 'UTC+00:00',
	},
	{
		name: 'Europe/Dublin',
		continentName: 'Europe',
		countryName: 'Ireland',
		abbreviation: 'GMT',
		utcOffset: 'UTC+00:00',
	},
	{
		name: 'Europe/Isle_of_Man',
		continentName: 'Europe',
		countryName: 'Isle of Man',
		abbreviation: 'GMT',
		utcOffset: 'UTC+00:00',
	},
	{
		name: 'Europe/Rome',
		continentName: 'Europe',
		countryName: 'Italy',
		abbreviation: 'CET',
		utcOffset: 'UTC+01:00',
	},
	{
		name: 'Europe/Jersey',
		continentName: 'Europe',
		countryName: 'Jersey',
		abbreviation: 'GMT',
		utcOffset: 'UTC+00:00',
	},
	{
		name: 'Europe/Riga',
		continentName: 'Europe',
		countryName: 'Latvia',
		abbreviation: 'EET',
		utcOffset: 'UTC+02:00',
	},
	{
		name: 'Europe/Vaduz',
		continentName: 'Europe',
		countryName: 'Liechtenstein',
		abbreviation: 'CET',
		utcOffset: 'UTC+01:00',
	},
	{
		name: 'Europe/Vilnius',
		continentName: 'Europe',
		countryName: 'Lithuania',
		abbreviation: 'EET',
		utcOffset: 'UTC+02:00',
	},
	{
		name: 'Europe/Luxembourg',
		continentName: 'Europe',
		countryName: 'Luxembourg',
		abbreviation: 'CET',
		utcOffset: 'UTC+01:00',
	},
	{
		name: 'Europe/Malta',
		continentName: 'Europe',
		countryName: 'Malta',
		abbreviation: 'CET',
		utcOffset: 'UTC+01:00',
	},
	{
		name: 'Europe/Chisinau',
		continentName: 'Europe',
		countryName: 'Moldova',
		abbreviation: 'EET',
		utcOffset: 'UTC+02:00',
	},
	{
		name: 'Europe/Monaco',
		continentName: 'Europe',
		countryName: 'Monaco',
		abbreviation: 'CET',
		utcOffset: 'UTC+01:00',
	},
	{
		name: 'Europe/Podgorica',
		continentName: 'Europe',
		countryName: 'Montenegro',
		abbreviation: 'CET',
		utcOffset: 'UTC+01:00',
	},
	{
		name: 'Europe/Skopje',
		continentName: 'Europe',
		countryName: 'North Macedonia',
		abbreviation: 'CET',
		utcOffset: 'UTC+01:00',
	},
	{
		name: 'Europe/Oslo',
		continentName: 'Europe',
		countryName: 'Norway',
		abbreviation: 'CET',
		utcOffset: 'UTC+01:00',
	},
	{
		name: 'Europe/Warsaw',
		continentName: 'Europe',
		countryName: 'Poland',
		abbreviation: 'CET',
		utcOffset: 'UTC+01:00',
	},
	{
		name: 'Atlantic/Azores',
		continentName: 'Europe',
		countryName: 'Portugal',
		abbreviation: 'AZOT',
		utcOffset: 'UTC-01:00',
	},
	{
		name: 'Europe/Lisbon',
		continentName: 'Europe',
		countryName: 'Portugal',
		abbreviation: 'WET',
		utcOffset: 'UTC+00:00',
	},
	{
		name: 'Europe/Bucharest',
		continentName: 'Europe',
		countryName: 'Romania',
		abbreviation: 'EET',
		utcOffset: 'UTC+02:00',
	},
	{
		name: 'Europe/Kaliningrad',
		continentName: 'Europe',
		countryName: 'Russia',
		abbreviation: 'EET',
		utcOffset: 'UTC+02:00',
	},
	{
		name: 'Europe/Moscow',
		continentName: 'Europe',
		countryName: 'Russia',
		abbreviation: 'MSK',
		utcOffset: 'UTC+03:00',
	},
	{
		name: 'Europe/Samara',
		continentName: 'Europe',
		countryName: 'Russia',
		abbreviation: 'SAMT',
		utcOffset: 'UTC+04:00',
	},
	{
		name: 'Asia/Yekaterinburg',
		continentName: 'Europe',
		countryName: 'Russia',
		abbreviation: 'YEKT',
		utcOffset: 'UTC+05:00',
	},
	{
		name: 'Asia/Omsk',
		continentName: 'Europe',
		countryName: 'Russia',
		abbreviation: 'OMST',
		utcOffset: 'UTC+06:00',
	},
	{
		name: 'Asia/Novosibirsk',
		continentName: 'Europe',
		countryName: 'Russia',
		abbreviation: 'NOVT',
		utcOffset: 'UTC+07:00',
	},
	{
		name: 'Asia/Irkutsk',
		continentName: 'Europe',
		countryName: 'Russia',
		abbreviation: 'IRKT',
		utcOffset: 'UTC+08:00',
	},
	{
		name: 'Asia/Chita',
		continentName: 'Europe',
		countryName: 'Russia',
		abbreviation: 'YAKT',
		utcOffset: 'UTC+09:00',
	},
	{
		name: 'Asia/Vladivostok',
		continentName: 'Europe',
		countryName: 'Russia',
		abbreviation: 'VLAT',
		utcOffset: 'UTC+10:00',
	},
	{
		name: 'Asia/Sakhalin',
		continentName: 'Europe',
		countryName: 'Russia',
		abbreviation: 'SAKT',
		utcOffset: 'UTC+11:00',
	},
	{
		name: 'Asia/Kamchatka',
		continentName: 'Europe',
		countryName: 'Russia',
		abbreviation: 'PETT',
		utcOffset: 'UTC+12:00',
	},
	{
		name: 'Europe/San_Marino',
		continentName: 'Europe',
		countryName: 'San Marino',
		abbreviation: 'CET',
		utcOffset: 'UTC+01:00',
	},
	{
		name: 'Europe/Belgrade',
		continentName: 'Europe',
		countryName: 'Serbia',
		abbreviation: 'CET',
		utcOffset: 'UTC+01:00',
	},
	{
		name: 'Europe/Bratislava',
		continentName: 'Europe',
		countryName: 'Slovakia',
		abbreviation: 'CET',
		utcOffset: 'UTC+01:00',
	},
	{
		name: 'Europe/Ljubljana',
		continentName: 'Europe',
		countryName: 'Slovenia',
		abbreviation: 'CET',
		utcOffset: 'UTC+01:00',
	},
	{
		name: 'Atlantic/Canary',
		continentName: 'Europe',
		countryName: 'Spain',
		abbreviation: 'WET',
		utcOffset: 'UTC+00:00',
	},
	{
		name: 'Europe/Madrid',
		continentName: 'Europe',
		countryName: 'Spain',
		abbreviation: 'CET',
		utcOffset: 'UTC+01:00',
	},
	{
		name: 'Arctic/Longyearbyen',
		continentName: 'Europe',
		countryName: 'Svalbard and Jan Mayen',
		abbreviation: 'CET',
		utcOffset: 'UTC+01:00',
	},
	{
		name: 'Europe/Stockholm',
		continentName: 'Europe',
		countryName: 'Sweden',
		abbreviation: 'CET',
		utcOffset: 'UTC+01:00',
	},
	{
		name: 'Europe/Zurich',
		continentName: 'Europe',
		countryName: 'Switzerland',
		abbreviation: 'CET',
		utcOffset: 'UTC+01:00',
	},
	{
		name: 'Europe/Amsterdam',
		continentName: 'Europe',
		countryName: 'The Netherlands',
		abbreviation: 'CET',
		utcOffset: 'UTC+01:00',
	},
	{
		name: 'Europe/Kyiv',
		continentName: 'Europe',
		countryName: 'Ukraine',
		abbreviation: 'EET',
		utcOffset: 'UTC+02:00',
	},
	{
		name: 'Europe/Simferopol',
		continentName: 'Europe',
		countryName: 'Ukraine',
		abbreviation: 'MSK',
		utcOffset: 'UTC+03:00',
	},
	{
		name: 'Europe/London',
		continentName: 'Europe',
		countryName: 'United Kingdom',
		abbreviation: 'GMT',
		utcOffset: 'UTC+00:00',
	},
	{
		name: 'Europe/Vatican',
		continentName: 'Europe',
		countryName: 'Vatican',
		abbreviation: 'CET',
		utcOffset: 'UTC+01:00',
	},
	{
		name: 'America/Anguilla',
		continentName: 'North America',
		countryName: 'Anguilla',
		abbreviation: 'AST',
		utcOffset: 'UTC-04:00',
	},
	{
		name: 'America/Antigua',
		continentName: 'North America',
		countryName: 'Antigua and Barbuda',
		abbreviation: 'AST',
		utcOffset: 'UTC-04:00',
	},
	{
		name: 'America/Aruba',
		continentName: 'North America',
		countryName: 'Aruba',
		abbreviation: 'AST',
		utcOffset: 'UTC-04:00',
	},
	{
		name: 'America/Nassau',
		continentName: 'North America',
		countryName: 'Bahamas',
		abbreviation: 'EST',
		utcOffset: 'UTC-05:00',
	},
	{
		name: 'America/Barbados',
		continentName: 'North America',
		countryName: 'Barbados',
		abbreviation: 'AST',
		utcOffset: 'UTC-04:00',
	},
	{
		name: 'America/Belize',
		continentName: 'North America',
		countryName: 'Belize',
		abbreviation: 'CST',
		utcOffset: 'UTC-06:00',
	},
	{
		name: 'Atlantic/Bermuda',
		continentName: 'North America',
		countryName: 'Bermuda',
		abbreviation: 'AST',
		utcOffset: 'UTC-04:00',
	},
	{
		name: 'America/Kralendijk',
		continentName: 'North America',
		countryName: 'Bonaire, Saint Eustatius and Saba ',
		abbreviation: 'AST',
		utcOffset: 'UTC-04:00',
	},
	{
		name: 'America/Tortola',
		continentName: 'North America',
		countryName: 'British Virgin Islands',
		abbreviation: 'AST',
		utcOffset: 'UTC-04:00',
	},
	{
		name: 'America/Vancouver',
		continentName: 'North America',
		countryName: 'Canada',
		abbreviation: 'PST',
		utcOffset: 'UTC-08:00',
	},
	{
		name: 'America/Edmonton',
		continentName: 'North America',
		countryName: 'Canada',
		abbreviation: 'MST',
		utcOffset: 'UTC-07:00',
	},
	{
		name: 'America/Whitehorse',
		continentName: 'North America',
		countryName: 'Canada',
		abbreviation: 'YT',
		utcOffset: 'UTC-07:00',
	},
	{
		name: 'America/Regina',
		continentName: 'North America',
		countryName: 'Canada',
		abbreviation: 'CST',
		utcOffset: 'UTC-06:00',
	},
	{
		name: 'America/Winnipeg',
		continentName: 'North America',
		countryName: 'Canada',
		abbreviation: 'CST',
		utcOffset: 'UTC-06:00',
	},
	{
		name: 'America/Atikokan',
		continentName: 'North America',
		countryName: 'Canada',
		abbreviation: 'EST',
		utcOffset: 'UTC-05:00',
	},
	{
		name: 'America/Toronto',
		continentName: 'North America',
		countryName: 'Canada',
		abbreviation: 'EST',
		utcOffset: 'UTC-05:00',
	},
	{
		name: 'America/Blanc-Sablon',
		continentName: 'North America',
		countryName: 'Canada',
		abbreviation: 'AST',
		utcOffset: 'UTC-04:00',
	},
	{
		name: 'America/Halifax',
		continentName: 'North America',
		countryName: 'Canada',
		abbreviation: 'AST',
		utcOffset: 'UTC-04:00',
	},
	{
		name: 'America/St_Johns',
		continentName: 'North America',
		countryName: 'Canada',
		abbreviation: 'NST',
		utcOffset: 'UTC-03:30',
	},
	{
		name: 'America/Cayman',
		continentName: 'North America',
		countryName: 'Cayman Islands',
		abbreviation: 'EST',
		utcOffset: 'UTC-05:00',
	},
	{
		name: 'America/Costa_Rica',
		continentName: 'North America',
		countryName: 'Costa Rica',
		abbreviation: 'CST',
		utcOffset: 'UTC-06:00',
	},
	{
		name: 'America/Havana',
		continentName: 'North America',
		countryName: 'Cuba',
		abbreviation: 'CST',
		utcOffset: 'UTC-05:00',
	},
	{
		name: 'America/Curacao',
		continentName: 'North America',
		countryName: 'Curacao',
		abbreviation: 'AST',
		utcOffset: 'UTC-04:00',
	},
	{
		name: 'America/Dominica',
		continentName: 'North America',
		countryName: 'Dominica',
		abbreviation: 'AST',
		utcOffset: 'UTC-04:00',
	},
	{
		name: 'America/Santo_Domingo',
		continentName: 'North America',
		countryName: 'Dominican Republic',
		abbreviation: 'AST',
		utcOffset: 'UTC-04:00',
	},
	{
		name: 'America/El_Salvador',
		continentName: 'North America',
		countryName: 'El Salvador',
		abbreviation: 'CST',
		utcOffset: 'UTC-06:00',
	},
	{
		name: 'America/Thule',
		continentName: 'North America',
		countryName: 'Greenland',
		abbreviation: 'AST',
		utcOffset: 'UTC-04:00',
	},
	{
		name: 'America/Nuuk',
		continentName: 'North America',
		countryName: 'Greenland',
		abbreviation: 'WGT',
		utcOffset: 'UTC-02:00',
	},
	{
		name: 'America/Scoresbysund',
		continentName: 'North America',
		countryName: 'Greenland',
		abbreviation: 'EGT',
		utcOffset: 'UTC-01:00',
	},
	{
		name: 'America/Danmarkshavn',
		continentName: 'North America',
		countryName: 'Greenland',
		abbreviation: 'GMT',
		utcOffset: 'UTC+00:00',
	},
	{
		name: 'America/Grenada',
		continentName: 'North America',
		countryName: 'Grenada',
		abbreviation: 'AST',
		utcOffset: 'UTC-04:00',
	},
	{
		name: 'America/Guadeloupe',
		continentName: 'North America',
		countryName: 'Guadeloupe',
		abbreviation: 'AST',
		utcOffset: 'UTC-04:00',
	},
	{
		name: 'America/Guatemala',
		continentName: 'North America',
		countryName: 'Guatemala',
		abbreviation: 'CST',
		utcOffset: 'UTC-06:00',
	},
	{
		name: 'America/Port-au-Prince',
		continentName: 'North America',
		countryName: 'Haiti',
		abbreviation: 'EST',
		utcOffset: 'UTC-05:00',
	},
	{
		name: 'America/Tegucigalpa',
		continentName: 'North America',
		countryName: 'Honduras',
		abbreviation: 'CST',
		utcOffset: 'UTC-06:00',
	},
	{
		name: 'America/Jamaica',
		continentName: 'North America',
		countryName: 'Jamaica',
		abbreviation: 'EST',
		utcOffset: 'UTC-05:00',
	},
	{
		name: 'America/Martinique',
		continentName: 'North America',
		countryName: 'Martinique',
		abbreviation: 'AST',
		utcOffset: 'UTC-04:00',
	},
	{
		name: 'America/Tijuana',
		continentName: 'North America',
		countryName: 'Mexico',
		abbreviation: 'PST',
		utcOffset: 'UTC-08:00',
	},
	{
		name: 'America/Hermosillo',
		continentName: 'North America',
		countryName: 'Mexico',
		abbreviation: 'GMT-7',
		utcOffset: 'UTC-07:00',
	},
	{
		name: 'America/Ciudad_Juarez',
		continentName: 'North America',
		countryName: 'Mexico',
		abbreviation: 'MST',
		utcOffset: 'UTC-07:00',
	},
	{
		name: 'America/Mexico_City',
		continentName: 'North America',
		countryName: 'Mexico',
		abbreviation: 'CST',
		utcOffset: 'UTC-06:00',
	},
	{
		name: 'America/Matamoros',
		continentName: 'North America',
		countryName: 'Mexico',
		abbreviation: 'CST',
		utcOffset: 'UTC-06:00',
	},
	{
		name: 'America/Cancun',
		continentName: 'North America',
		countryName: 'Mexico',
		abbreviation: 'EST',
		utcOffset: 'UTC-05:00',
	},
	{
		name: 'America/Montserrat',
		continentName: 'North America',
		countryName: 'Montserrat',
		abbreviation: 'AST',
		utcOffset: 'UTC-04:00',
	},
	{
		name: 'America/Managua',
		continentName: 'North America',
		countryName: 'Nicaragua',
		abbreviation: 'CST',
		utcOffset: 'UTC-06:00',
	},
	{
		name: 'America/Panama',
		continentName: 'North America',
		countryName: 'Panama',
		abbreviation: 'EST',
		utcOffset: 'UTC-05:00',
	},
	{
		name: 'America/Puerto_Rico',
		continentName: 'North America',
		countryName: 'Puerto Rico',
		abbreviation: 'AST',
		utcOffset: 'UTC-04:00',
	},
	{
		name: 'America/St_Barthelemy',
		continentName: 'North America',
		countryName: 'Saint Barthelemy',
		abbreviation: 'AST',
		utcOffset: 'UTC-04:00',
	},
	{
		name: 'America/St_Kitts',
		continentName: 'North America',
		countryName: 'Saint Kitts and Nevis',
		abbreviation: 'AST',
		utcOffset: 'UTC-04:00',
	},
	{
		name: 'America/St_Lucia',
		continentName: 'North America',
		countryName: 'Saint Lucia',
		abbreviation: 'AST',
		utcOffset: 'UTC-04:00',
	},
	{
		name: 'America/Marigot',
		continentName: 'North America',
		countryName: 'Saint Martin',
		abbreviation: 'AST',
		utcOffset: 'UTC-04:00',
	},
	{
		name: 'America/Miquelon',
		continentName: 'North America',
		countryName: 'Saint Pierre and Miquelon',
		abbreviation: 'PM',
		utcOffset: 'UTC-03:00',
	},
	{
		name: 'America/St_Vincent',
		continentName: 'North America',
		countryName: 'Saint Vincent and the Grenadines',
		abbreviation: 'AST',
		utcOffset: 'UTC-04:00',
	},
	{
		name: 'America/Lower_Princes',
		continentName: 'North America',
		countryName: 'Sint Maarten',
		abbreviation: 'AST',
		utcOffset: 'UTC-04:00',
	},
	{
		name: 'America/Port_of_Spain',
		continentName: 'North America',
		countryName: 'Trinidad and Tobago',
		abbreviation: 'AST',
		utcOffset: 'UTC-04:00',
	},
	{
		name: 'America/Grand_Turk',
		continentName: 'North America',
		countryName: 'Turks and Caicos Islands',
		abbreviation: 'EST',
		utcOffset: 'UTC-05:00',
	},
	{
		name: 'America/St_Thomas',
		continentName: 'North America',
		countryName: 'U.S. Virgin Islands',
		abbreviation: 'AST',
		utcOffset: 'UTC-04:00',
	},
	{
		name: 'America/Adak',
		continentName: 'North America',
		countryName: 'United States',
		abbreviation: 'HAST',
		utcOffset: 'UTC-10:00',
	},
	{
		name: 'Pacific/Honolulu',
		continentName: 'North America',
		countryName: 'United States',
		abbreviation: 'HAST',
		utcOffset: 'UTC-10:00',
	},
	{
		name: 'America/Anchorage',
		continentName: 'North America',
		countryName: 'United States',
		abbreviation: 'AKST',
		utcOffset: 'UTC-09:00',
	},
	{
		name: 'America/Los_Angeles',
		continentName: 'North America',
		countryName: 'United States',
		abbreviation: 'PST',
		utcOffset: 'UTC-08:00',
	},
	{
		name: 'America/Denver',
		continentName: 'North America',
		countryName: 'United States',
		abbreviation: 'MST',
		utcOffset: 'UTC-07:00',
	},
	{
		name: 'America/Phoenix',
		continentName: 'North America',
		countryName: 'United States',
		abbreviation: 'MST',
		utcOffset: 'UTC-07:00',
	},
	{
		name: 'America/Chicago',
		continentName: 'North America',
		countryName: 'United States',
		abbreviation: 'CST',
		utcOffset: 'UTC-06:00',
	},
	{
		name: 'America/New_York',
		continentName: 'North America',
		countryName: 'United States',
		abbreviation: 'EST',
		utcOffset: 'UTC-05:00',
	},
	{
		name: 'Pacific/Pago_Pago',
		continentName: 'Oceania',
		countryName: 'American Samoa',
		abbreviation: 'SST',
		utcOffset: 'UTC-11:00',
	},
	{
		name: 'Australia/Perth',
		continentName: 'Oceania',
		countryName: 'Australia',
		abbreviation: 'AWST',
		utcOffset: 'UTC+08:00',
	},
	{
		name: 'Australia/Eucla',
		continentName: 'Oceania',
		countryName: 'Australia',
		abbreviation: 'ACWST',
		utcOffset: 'UTC+08:45',
	},
	{
		name: 'Australia/Adelaide',
		continentName: 'Oceania',
		countryName: 'Australia',
		abbreviation: 'ACST',
		utcOffset: 'UTC+09:30',
	},
	{
		name: 'Australia/Darwin',
		continentName: 'Oceania',
		countryName: 'Australia',
		abbreviation: 'ACST',
		utcOffset: 'UTC+09:30',
	},
	{
		name: 'Australia/Brisbane',
		continentName: 'Oceania',
		countryName: 'Australia',
		abbreviation: 'AEST',
		utcOffset: 'UTC+10:00',
	},
	{
		name: 'Australia/Sydney',
		continentName: 'Oceania',
		countryName: 'Australia',
		abbreviation: 'AEST',
		utcOffset: 'UTC+10:00',
	},
	{
		name: 'Australia/Lord_Howe',
		continentName: 'Oceania',
		countryName: 'Australia',
		abbreviation: 'LHST',
		utcOffset: 'UTC+10:30',
	},
	{
		name: 'Indian/Christmas',
		continentName: 'Oceania',
		countryName: 'Christmas Island',
		abbreviation: 'CXT',
		utcOffset: 'UTC+07:00',
	},
	{
		name: 'Pacific/Rarotonga',
		continentName: 'Oceania',
		countryName: 'Cook Islands',
		abbreviation: 'CKT',
		utcOffset: 'UTC-10:00',
	},
	{
		name: 'Pacific/Fiji',
		continentName: 'Oceania',
		countryName: 'Fiji',
		abbreviation: 'FJT',
		utcOffset: 'UTC+12:00',
	},
	{
		name: 'Pacific/Tahiti',
		continentName: 'Oceania',
		countryName: 'French Polynesia',
		abbreviation: 'TAHT',
		utcOffset: 'UTC-10:00',
	},
	{
		name: 'Pacific/Marquesas',
		continentName: 'Oceania',
		countryName: 'French Polynesia',
		abbreviation: 'MART',
		utcOffset: 'UTC-09:30',
	},
	{
		name: 'Pacific/Gambier',
		continentName: 'Oceania',
		countryName: 'French Polynesia',
		abbreviation: 'GAMT',
		utcOffset: 'UTC-09:00',
	},
	{
		name: 'Pacific/Guam',
		continentName: 'Oceania',
		countryName: 'Guam',
		abbreviation: 'ChST',
		utcOffset: 'UTC+10:00',
	},
	{
		name: 'Pacific/Tarawa',
		continentName: 'Oceania',
		countryName: 'Kiribati',
		abbreviation: 'GILT',
		utcOffset: 'UTC+12:00',
	},
	{
		name: 'Pacific/Kanton',
		continentName: 'Oceania',
		countryName: 'Kiribati',
		abbreviation: 'PHOT',
		utcOffset: 'UTC+13:00',
	},
	{
		name: 'Pacific/Kiritimati',
		continentName: 'Oceania',
		countryName: 'Kiribati',
		abbreviation: 'LINT',
		utcOffset: 'UTC+14:00',
	},
	{
		name: 'Pacific/Majuro',
		continentName: 'Oceania',
		countryName: 'Marshall Islands',
		abbreviation: 'MHT',
		utcOffset: 'UTC+12:00',
	},
	{
		name: 'Pacific/Chuuk',
		continentName: 'Oceania',
		countryName: 'Micronesia',
		abbreviation: 'CHUT',
		utcOffset: 'UTC+10:00',
	},
	{
		name: 'Pacific/Kosrae',
		continentName: 'Oceania',
		countryName: 'Micronesia',
		abbreviation: 'KOST',
		utcOffset: 'UTC+11:00',
	},
	{
		name: 'Pacific/Nauru',
		continentName: 'Oceania',
		countryName: 'Nauru',
		abbreviation: 'NRT',
		utcOffset: 'UTC+12:00',
	},
	{
		name: 'Pacific/Noumea',
		continentName: 'Oceania',
		countryName: 'New Caledonia',
		abbreviation: 'NCT',
		utcOffset: 'UTC+11:00',
	},
	{
		name: 'Pacific/Auckland',
		continentName: 'Oceania',
		countryName: 'New Zealand',
		abbreviation: 'NZST',
		utcOffset: 'UTC+12:00',
	},
	{
		name: 'Pacific/Chatham',
		continentName: 'Oceania',
		countryName: 'New Zealand',
		abbreviation: 'CHAST',
		utcOffset: 'UTC+12:45',
	},
	{
		name: 'Pacific/Niue',
		continentName: 'Oceania',
		countryName: 'Niue',
		abbreviation: 'NUT',
		utcOffset: 'UTC-11:00',
	},
	{
		name: 'Pacific/Norfolk',
		continentName: 'Oceania',
		countryName: 'Norfolk Island',
		abbreviation: 'NFT',
		utcOffset: 'UTC+11:00',
	},
	{
		name: 'Pacific/Saipan',
		continentName: 'Oceania',
		countryName: 'Northern Mariana Islands',
		abbreviation: 'ChST',
		utcOffset: 'UTC+10:00',
	},
	{
		name: 'Pacific/Palau',
		continentName: 'Oceania',
		countryName: 'Palau',
		abbreviation: 'PWT',
		utcOffset: 'UTC+09:00',
	},
	{
		name: 'Pacific/Port_Moresby',
		continentName: 'Oceania',
		countryName: 'Papua New Guinea',
		abbreviation: 'PGT',
		utcOffset: 'UTC+10:00',
	},
	{
		name: 'Pacific/Bougainville',
		continentName: 'Oceania',
		countryName: 'Papua New Guinea',
		abbreviation: 'BST',
		utcOffset: 'UTC+11:00',
	},
	{
		name: 'Pacific/Pitcairn',
		continentName: 'Oceania',
		countryName: 'Pitcairn',
		abbreviation: 'PST',
		utcOffset: 'UTC-08:00',
	},
	{
		name: 'Pacific/Apia',
		continentName: 'Oceania',
		countryName: 'Samoa',
		abbreviation: 'WST',
		utcOffset: 'UTC+13:00',
	},
	{
		name: 'Pacific/Guadalcanal',
		continentName: 'Oceania',
		countryName: 'Solomon Islands',
		abbreviation: 'SBT',
		utcOffset: 'UTC+11:00',
	},
	{
		name: 'Asia/Dili',
		continentName: 'Oceania',
		countryName: 'Timor Leste',
		abbreviation: 'TLT',
		utcOffset: 'UTC+09:00',
	},
	{
		name: 'Pacific/Fakaofo',
		continentName: 'Oceania',
		countryName: 'Tokelau',
		abbreviation: 'TKT',
		utcOffset: 'UTC+13:00',
	},
	{
		name: 'Pacific/Tongatapu',
		continentName: 'Oceania',
		countryName: 'Tonga',
		abbreviation: 'TOT',
		utcOffset: 'UTC+13:00',
	},
	{
		name: 'Pacific/Funafuti',
		continentName: 'Oceania',
		countryName: 'Tuvalu',
		abbreviation: 'TVT',
		utcOffset: 'UTC+12:00',
	},
	{
		name: 'Pacific/Midway',
		continentName: 'Oceania',
		countryName: 'United States Minor Outlying Islands',
		abbreviation: 'SST',
		utcOffset: 'UTC-11:00',
	},
	{
		name: 'Pacific/Wake',
		continentName: 'Oceania',
		countryName: 'United States Minor Outlying Islands',
		abbreviation: 'WAKT',
		utcOffset: 'UTC+12:00',
	},
	{
		name: 'Pacific/Efate',
		continentName: 'Oceania',
		countryName: 'Vanuatu',
		abbreviation: 'VUT',
		utcOffset: 'UTC+11:00',
	},
	{
		name: 'Pacific/Wallis',
		continentName: 'Oceania',
		countryName: 'Wallis and Futuna',
		abbreviation: 'WFT',
		utcOffset: 'UTC+12:00',
	},
	{
		name: 'America/Argentina/Buenos_Aires',
		continentName: 'South America',
		countryName: 'Argentina',
		abbreviation: 'ART',
		utcOffset: 'UTC-03:00',
	},
	{
		name: 'America/La_Paz',
		continentName: 'South America',
		countryName: 'Bolivia',
		abbreviation: 'BOT',
		utcOffset: 'UTC-04:00',
	},
	{
		name: 'America/Rio_Branco',
		continentName: 'South America',
		countryName: 'Brazil',
		abbreviation: 'ACT',
		utcOffset: 'UTC-05:00',
	},
	{
		name: 'America/Manaus',
		continentName: 'South America',
		countryName: 'Brazil',
		abbreviation: 'AMT',
		utcOffset: 'UTC-04:00',
	},
	{
		name: 'America/Sao_Paulo',
		continentName: 'South America',
		countryName: 'Brazil',
		abbreviation: 'BRT',
		utcOffset: 'UTC-03:00',
	},
	{
		name: 'America/Noronha',
		continentName: 'South America',
		countryName: 'Brazil',
		abbreviation: 'FNT',
		utcOffset: 'UTC-02:00',
	},
	{
		name: 'Pacific/Easter',
		continentName: 'South America',
		countryName: 'Chile',
		abbreviation: 'EAST',
		utcOffset: 'UTC-06:00',
	},
	{
		name: 'America/Santiago',
		continentName: 'South America',
		countryName: 'Chile',
		abbreviation: 'CLT',
		utcOffset: 'UTC-04:00',
	},
	{
		name: 'America/Punta_Arenas',
		continentName: 'South America',
		countryName: 'Chile',
		abbreviation: 'CLT',
		utcOffset: 'UTC-03:00',
	},
	{
		name: 'America/Bogota',
		continentName: 'South America',
		countryName: 'Colombia',
		abbreviation: 'COT',
		utcOffset: 'UTC-05:00',
	},
	{
		name: 'Pacific/Galapagos',
		continentName: 'South America',
		countryName: 'Ecuador',
		abbreviation: 'GALT',
		utcOffset: 'UTC-06:00',
	},
	{
		name: 'America/Guayaquil',
		continentName: 'South America',
		countryName: 'Ecuador',
		abbreviation: 'ECT',
		utcOffset: 'UTC-05:00',
	},
	{
		name: 'Atlantic/Stanley',
		continentName: 'South America',
		countryName: 'Falkland Islands',
		abbreviation: 'FKST',
		utcOffset: 'UTC-03:00',
	},
	{
		name: 'America/Cayenne',
		continentName: 'South America',
		countryName: 'French Guiana',
		abbreviation: 'GFT',
		utcOffset: 'UTC-03:00',
	},
	{
		name: 'America/Guyana',
		continentName: 'South America',
		countryName: 'Guyana',
		abbreviation: 'GYT',
		utcOffset: 'UTC-04:00',
	},
	{
		name: 'America/Asuncion',
		continentName: 'South America',
		countryName: 'Paraguay',
		abbreviation: 'PYT',
		utcOffset: 'UTC-04:00',
	},
	{
		name: 'America/Lima',
		continentName: 'South America',
		countryName: 'Peru',
		abbreviation: 'PET',
		utcOffset: 'UTC-05:00',
	},
	{
		name: 'America/Paramaribo',
		continentName: 'South America',
		countryName: 'Suriname',
		abbreviation: 'SRT',
		utcOffset: 'UTC-03:00',
	},
	{
		name: 'America/Montevideo',
		continentName: 'South America',
		countryName: 'Uruguay',
		abbreviation: 'UYT',
		utcOffset: 'UTC-03:00',
	},
	{
		name: 'America/Caracas',
		continentName: 'South America',
		countryName: 'Venezuela',
		abbreviation: 'VET',
		utcOffset: 'UTC-04:00',
	},
];
