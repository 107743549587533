// Magic number disabled, since some are used to randomize style options for ballon animation
/* eslint-disable no-magic-numbers */

import { Component, EventEmitter, Input, Output } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FADE_ANIMATION } from '../../app.animation';
import { GroupUiModule } from '@group-ui/group-ui-angular';
import { TranslocoRootModule } from '@core/i18n/transloco-root.module';
import { TranslocoTestingModule } from '@ngneat/transloco';

@Component({
	selector: 'btr-app-update-modal',
	standalone: true,
	imports: [CommonModule, GroupUiModule, TranslocoRootModule, TranslocoTestingModule],
	templateUrl: './app-update-modal.component.html',
	styleUrls: ['./app-update-modal.component.scss'],
	animations: FADE_ANIMATION,
})
export class AppUpdateModalComponent {
	@Input() showUpdateAppModal = false;
	@Output() showUpdateAppModalChange = new EventEmitter();
	@Input() amount = 10;
	showBalloons = true;
	balloonStyleList: string[] = [];
	onUpdateApp() {
		document.location.reload();
	}
	onLoad() {
		this.showBalloons = true;
		this.renderBalloons();
	}
	private renderBalloons() {
		this.balloonStyleList = new Array(this.amount).fill(0).map(() => this.getRandomStyles());
	}
	getRandomStyles() {
		const r = this.random(255);
		const g = this.random(255);
		const b = this.random(255);
		const mt = this.random(200);
		const ml = this.random(50);
		const dur = this.random(2) + 2;
		return `
			background-color: rgba(${r},${g},${b},0.7);
			color: rgba(${r},${g},${b},0.7);
			box-shadow: inset -7px -3px 10px rgba(${r - 10},${g - 10},${b - 10},0.7);
			margin: ${mt}px 0 0 ${ml}px;
			animation-duration: ${dur}s
  			`;
	}
	random(num: number) {
		return Math.floor(Math.random() * num);
	}
}
