/**
 * @description Use in apollo.use target specific graphql endpoint.
 * Enum values must match the fields defined in the environment.backend.graphql
 * Explicit Testing skipped since typescript will throw type error
 */

export enum APOLLO_CLIENT_NAME {
	CONTRACT_MANAGEMENT = 'backendURLContractManagement',
	COMPONENT_MANAGEMENT = 'backendURLComponentManagement',
	MASTER_DATA_MANAGEMENT = 'backendURLMasterDataManagement',
	B2B_ADAPTER = 'backendURLB2bAdapter',
}
