import { animate, style, transition, trigger } from '@angular/animations';

import { environment } from '../environments/environment';

export const FADE_ANIMATION = [
	trigger('fade', [
		transition(':enter', [style({ opacity: 0 }), animate(environment.config.animation.fadeTimeMS)]),
		transition(':leave', animate(environment.config.animation.fadeTimeMS, style({ opacity: 0 }))),
	]),
	trigger('fadeIn', [transition(':enter', [style({ opacity: 0 }), animate(environment.config.animation.fadeTimeMS)])]),
];
