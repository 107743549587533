import { Component, inject } from '@angular/core';

import { CommonModule } from '@angular/common';
import { DiscardChangesGuard } from './discard-changes.guard';
import { DiscardChangesModal } from './discard-changes.model';
import { GroupUiModule } from '@group-ui/group-ui-angular';
import { Observable } from 'rxjs';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
	selector: 'app-groupui-discard-changes-modal',
	standalone: true,
	imports: [CommonModule, GroupUiModule, TranslocoModule],
	templateUrl: './groupui-discard-changes-modal.component.html',
	styleUrl: './groupui-discard-changes-modal.component.scss',
})
export class GroupuiDiscardChangesModalComponent {
	readonly discardChanges$: Observable<DiscardChangesModal | null> = inject(DiscardChangesGuard).showDiscardChanges$;
}
