<ng-container *transloco="let t">
	<groupui-modal
		m="12"
		l="8"
		xl="6"
		xxl="4"
		(groupuiDidLoad)="onLoad()"
		[displayed]="showUpdateAppModal"
		[closeButton]="true"
		[disableBackdropClose]="true"
		[disableEscapeClose]="true"
		*ngIf="showUpdateAppModal">
		<div class="position-relative display-flex flex-direction-column gap-groupui-spacing-7" (mouseenter)="showBalloons = false">
			<groupui-headline heading="h3">{{ 'APP.SERVICE_WORKER.MODAL_TITLE#UPDATE_REQUEST' | transloco }}</groupui-headline>
			<groupui-text>{{ 'APP.SERVICE_WORKER.MODAL_MESSAGE#UPDATE_REQUEST' | transloco }}</groupui-text>
			<div class="display-flex gap-groupui-spacing-3 justify-content-flex-end">
				<groupui-button
					variant="secondary"
					(click)="showUpdateAppModal = false; showUpdateAppModalChange.emit(false)"
					data-test="btn-secondary"
					icon="close-24">
					{{ t('SHARED.BUTTON.CANCEL') }}
				</groupui-button>
				<groupui-button variant="primary" (click)="onUpdateApp()" data-test="btn-primary" icon="reload-24">
					{{ t('SHARED.BUTTON.RELOAD') }}
				</groupui-button>
			</div>
			<div class="position-absolute balloon-container" *ngIf="showBalloons" @fade>
				<div data-test="balloon" class="balloon" *ngFor="let balloon of balloonStyleList" [attr.style]="balloon"></div>
			</div>
		</div>
	</groupui-modal>
</ng-container>
